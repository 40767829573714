import React from "react"
import { Styled } from "theme-ui"

export default function Bio() {
  return (
    <>
      <Styled.a>Sam Rae</Styled.a>
    </>
  )
}
